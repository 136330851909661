import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CampaignsDisplay.css';

function CampaignsDisplay() {
  const [campaigns, setCampaigns] = useState([]);
  const [expandedCampaignId, setExpandedCampaignId] = useState(null);
  const [expandedEmailId, setExpandedEmailId] = useState(null);

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const fetchCampaigns = async () => {
    try {
      const response = await axios.get('/api/campaigns');
      console.log(response.data); // Add this to check what you receive for createdAt
      setCampaigns(response.data);
    } catch (error) {
      console.error('Failed to fetch campaigns:', error);
    }
  };

  const toggleCampaign = (id) => {
    setExpandedCampaignId(expandedCampaignId === id ? null : id);
    setExpandedEmailId(null); // Reset expanded email when toggling campaigns
  };

  const toggleEmail = (emailId) => {
    setExpandedEmailId(expandedEmailId === emailId ? null : emailId);
  };

  return (
    <div className="campaigns-container">
      <h2>Campaigns and Analytics</h2>
      <table className="campaigns-table">
        <thead>
          <tr>
            <th>Campaign ID</th>
            <th>Total Emails Sent</th>
            <th>Opened</th>
            <th>Open Rate</th>
            <th>Created At</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {campaigns.map(campaign => (
            <React.Fragment key={campaign.id}>
              <tr onClick={() => toggleCampaign(campaign.id)}>
                <td>{campaign.id}</td>
                <td>{campaign.emails.length}</td>
                <td>{campaign.openedEmailsCount}</td>
                <td>{((campaign.openedEmailsCount / campaign.emails.length) * 100).toFixed(0)}%</td>
                <td>{new Date(campaign.createdAt).toLocaleString(undefined, { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</td>
                <td>{expandedCampaignId === campaign.id ? '-' : '+'}</td>
              </tr>
              {expandedCampaignId === campaign.id && campaign.emails.map(email => (
                <tr key={email.id} className="email-detail">
                  <td colSpan="6" onClick={() => toggleEmail(email.id)}>
                    <div className="email-summary">
                      <strong>To:</strong> {email.to}
                      <div className="email-message-preview">
                        {expandedEmailId === email.id ? email.message : `${email.message.substring(0, 100)}...`}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CampaignsDisplay;
