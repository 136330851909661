import React, { useState } from 'react';
import mixpanel from 'mixpanel-browser';

mixpanel.init('7c4d281c7bf333522cc04c068236a862');

const UserGoalInputForm = ({ onSubmitGoal }) => {
  const [goal, setGoal] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmitGoal(goal);

    // Track the "Next" button click event
    mixpanel.track('Goal Next Button Clicked', {
      goal: goal,
    });
  };

  return (
    <form type="goal" onSubmit={handleSubmit}>
      <label htmlFor="goal">What's your goal for this campaign?</label>
      <textarea
        id="goalInput"
        type="goal"
        value={goal}
        onChange={(e) => setGoal(e.target.value)}
        placeholder="Ex.: Introduce our productivity SaaS tool..."
        required
      ></textarea>
      <button type="submit">Next</button>
    </form>
  );
};

export default UserGoalInputForm;
