// src/components/Waiting.js

import React from 'react';

const Waiting = () => {
  return (
    <div>
      <h1>Waiting for Onboarding</h1>
      <p>Your onboarding process is underway. Please wait for further instructions.</p>
    </div>
  );
};

export default Waiting;
