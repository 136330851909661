import React, { useState, useEffect } from 'react';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase-config';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, sendEmailVerification } from 'firebase/auth';
import './SignPage.css';
import feature from '../assets/feature.png';
import logo from '../assets/logo.png';

mixpanel.init('7c4d281c7bf333522cc04c068236a862');

function SignPage({ onSignIn }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [isVerificationSent, setIsVerificationSent] = useState(false);
  const [signupAllowed, setSignupAllowed] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    const checkSignupAvailability = async () => {
      try {
        const response = await axios.get('/api/count-authenticated-users');
        if (response.data.count >= 10) {
          setSignupAllowed(false);
        }
      } catch (error) {
        console.error('Error checking signup availability:', error);
      } finally {
        setInitialLoading(false);
      }
    };

    checkSignupAvailability();
  }, []);

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);
  const handleFirstNameChange = (e) => setFirstName(e.target.value);
  const handleLastNameChange = (e) => setLastName(e.target.value);

  const getFriendlyErrorMessage = (error) => {
    switch (error.code) {
      case 'auth/email-already-in-use':
        return 'This email is already in use. Please use a different email.';
      case 'auth/invalid-email':
        return 'The email address is not valid.';
      case 'auth/weak-password':
        return 'The password is too weak. Please use a stronger password.';
      case 'auth/user-not-found':
        return 'No account found with this email.';
      case 'auth/wrong-password':
        return 'Incorrect password. Please try again.';
      case 'auth/invalid-credential':
        return 'Invalid credentials. Please check your email and password.';
      default:
        return 'An error occurred. Please try again.';
    }
  };

  const handleEmailSignUp = async () => {
    if (password !== confirmPassword) {
      setError("Passwords don't match. Please try again.");
      return;
    }

    setLoading(true);
    setError('');
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await sendEmailVerification(user);
      await axios.post('/api/signup-email', { email, uid: user.uid, firstName, lastName });
      setIsVerificationSent(true);
      mixpanel.track('Sign Up', { email: user.email });
      // Do not navigate to home, stay on the sign-up page until email is verified
    } catch (error) {
      console.error('Error signing up:', error);
      setError(getFriendlyErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleEmailSignIn = async () => {
    setLoading(true);
    setError('');
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (!user.emailVerified) {
        setError('Please verify your email before signing in.');
        setLoading(false);
        return;
      }

      const idToken = await user.getIdToken();
      await handleSessionLogin(idToken);
      onSignIn(idToken);
      mixpanel.track('Log In', { email: user.email });
      navigate('/'); // Redirect to homepage after login
    } catch (error) {
      console.error('Error signing in:', error);
      setError(getFriendlyErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReset = async () => {
    setLoading(true);
    setError('');
    try {
      await sendPasswordResetEmail(auth, email);
      setError('Password reset email sent. Please check your inbox.');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setError(getFriendlyErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleSessionLogin = async (token) => {
    try {
      await axios.post('/api/session-login', { token });
    } catch (error) {
      console.error('Error during session login:', error);
      setError('Failed to establish session. Please try again.');
    }
  };

  const renderVerificationMessage = () => (
    <div className="verification-message">
      <h3>Verification Email Sent</h3>
      <p>Please check your email and click on the verification link to complete your registration.</p>
      <button onClick={() => setIsVerificationSent(false)}>Back to Sign In</button>
    </div>
  );

  if (initialLoading) {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="sign-page">
      <div className="sign-container">
        <img src={logo} alt="Logo" className="logo" />
        <h2>Welcome to Grafema!</h2>
        {error && <p className="error-message">{error}</p>}
        {isVerificationSent ? renderVerificationMessage() : (
          <div className="sign-form">
            {!signupAllowed && isSignUp ? (
              <div className="limit-reached-message">
                <h3>We've reached our limit for now</h3>
                <p>Please apply to our <a href="https://www.grafema.xyz/" target="_blank" rel="noopener noreferrer">waitlist</a> to try it soon.</p>
                <button className="text-button" onClick={() => setIsSignUp(!isSignUp)} disabled={loading}>{isSignUp ? 'Log In' : 'Sign Up'}</button>
              </div>
            ) : (
              <>
                {isSignUp && (
                  <>
                    <input type="text" placeholder="First Name" value={firstName} onChange={handleFirstNameChange} disabled={loading} />
                    <input type="text" placeholder="Last Name" value={lastName} onChange={handleLastNameChange} disabled={loading} />
                  </>
                )}
                <input type="email" placeholder="Email" value={email} onChange={handleEmailChange} disabled={loading} />
                {!isResetPassword && (
                  <>
                    <input type="password" placeholder="Password" value={password} onChange={handlePasswordChange} disabled={loading} />
                    {isSignUp && <input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={handleConfirmPasswordChange} disabled={loading} />}
                  </>
                )}
                {isResetPassword ? (
                  <button onClick={handlePasswordReset} disabled={loading}>{loading ? 'Sending...' : 'Send Password Reset Email'}</button>
                ) : isSignUp ? (
                  <button onClick={handleEmailSignUp} disabled={loading}>{loading ? 'Signing Up...' : 'Sign up'}</button>
                ) : (
                  <button onClick={handleEmailSignIn} disabled={loading}>{loading ? 'Logging In...' : 'Log in'}</button>
                )}
                {!isResetPassword && (
                  <>
                    <button className="text-button" onClick={() => setIsSignUp(!isSignUp)} disabled={loading}>{isSignUp ? 'Log In' : 'Sign Up'}</button>
                    <button className="text-button" onClick={() => setIsResetPassword(true)} disabled={loading}>Forgot Password?</button>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div className="info-container">
        <div className="image-placeholder">
          <img src={feature} alt="Feature" />
        </div>
        <p className='text'>Personalized outreach that is 10x faster.<br /><br /> Try it for free! </p>
      </div>
    </div>
  );
}

export default SignPage;
