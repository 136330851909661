import React, { useState, useEffect, useRef } from 'react';
import { ClipLoader } from 'react-spinners';
import mixpanel from 'mixpanel-browser';

mixpanel.init('7c4d281c7bf333522cc04c068236a862');

function WebsiteInputForm({ 
  onSubmitUrls, 
  loading, 
  businessInfo, 
  showGenerateButton, 
  onEmailSubjectChange, 
  onSignatureChange,
  emailSubject, 
  signature 
}) {
  const [urls, setUrls] = useState('');
  const [localEmailSubject, setLocalEmailSubject] = useState(emailSubject);
  const [localSignature, setLocalSignature] = useState(signature);
  const [language, setLanguage] = useState(''); // Add state for language selection

  const subjectRef = useRef(null);
  const signatureRef = useRef(null);

  useEffect(() => {
    if (emailSubject !== localEmailSubject) {
      setLocalEmailSubject(emailSubject);
      subjectRef.current && subjectRef.current.focus();
    }
  }, [emailSubject]);

  useEffect(() => {
    if (signature !== localSignature) {
      setLocalSignature(signature);
      signatureRef.current && signatureRef.current.focus();
    }
  }, [signature]);

  const handleSubjectChange = (e) => {
    const newSubject = e.target.value;
    setLocalEmailSubject(newSubject);
    onEmailSubjectChange(newSubject);
  };

  const handleSignatureChange = (e) => {
    const newSignature = e.target.value;
    setLocalSignature(newSignature);
    onSignatureChange(newSignature);
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value); 
    console.log('afterChange', language)
};

  const handleSubmit = (event) => {
    event.preventDefault();
    const urlList = urls.split('\n').filter(url => url);

    console.log('Language before submit:', language);

    // Track the "Generate Emails" button click event
    mixpanel.track('Generate Emails Clicked', {
      emailSubject: localEmailSubject,
      signature: localSignature,
      language,
      urls: urlList.length,
    });

    onSubmitUrls(urlList, language); // Make sure you're passing the language here
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const paste = (event.clipboardData || window.clipboardData).getData('text');
    const extractedUrls = extractMainUrls(paste);
    const newUrls = extractedUrls.join('\n');
    setUrls(prevUrls => prevUrls ? `${prevUrls}\n${newUrls}` : newUrls);
  };

  const extractMainUrls = (input) => {
    const urlPattern = /https?:\/\/[^\s/$.?#].[^\s]*/g;
    const matches = input.match(urlPattern);
    if (matches) {
      const cleanedUrls = matches.map(url => {
        try {
          const { hostname } = new URL(url);
          return `https://${hostname}`;
        } catch (error) {
          return `https://${url.replace(/^(https?:\/\/)?/, '')}`;
        }
      });
      return Array.from(new Set(cleanedUrls)); // Remove duplicates
    }
    return [];
  };

  return (
    <div className='info-input-form'>
      <form encType="multipart/form-data">
        <h2>Email Subject</h2>
        <input
          type="text"
          placeholder="Enter email subject"
          value={localEmailSubject}
          onChange={handleSubjectChange}
          ref={subjectRef}
          required
        />
        <h2>Signature</h2>
        <input
          type="text"
          placeholder="Enter signature"
          value={localSignature}
          onChange={handleSignatureChange}
          ref={signatureRef}
          required
        />
        <h2>Select Language</h2>
        <div className="language-selector">
          <select value={language} onChange={handleLanguageChange}>
            <option value="English">English</option>
            <option value="Portuguese">Portuguese</option>
          </select>
        </div>
        <h2>Website URLs</h2>
        <textarea
          value={urls}
          onChange={(e) => setUrls(e.target.value)}
          onPaste={handlePaste}
          placeholder="Add 1 link per line"
          rows="5"
        ></textarea>
        <p>We suggest you don't exceed 100 emails per day, risking being marked as spam</p>
        <div className='upload-csv'>
          <div className='upload-csv-button'>
            <p>Or upload a CSV</p>
            <div className="upload-placeholder">
              <i className="fa fa-hourglass-half" aria-hidden="true"></i>
              <span className="tooltip">Available soon</span>
            </div>
          </div>
        </div>
        {showGenerateButton && businessInfo.length === 0 && (
          <button onClick={handleSubmit} type="submit" disabled={loading}>
            {loading ? (
              <ClipLoader color="#ffffff" loading={true} size={20} />
            ) : (
              'Generate Emails'
            )}
          </button>
        )}
      </form>
    </div>
  );
}

export default WebsiteInputForm;
