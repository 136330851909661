import React from 'react';
import './VerificationTutorial.css';

const VerificationTutorial = () => {
  return (
    <div className="tutorial-container">
      <h1 className="tutorial-title">Domain Verification Tutorial</h1>
      <p className="tutorial-intro">
        Before you can start sending emails, it's important to verify and authenticate your domain. This step is necessary because we use <a href="https://www.brevo.com" target="_blank" rel="noopener noreferrer" className="brevo-link">Brevo</a> to send your emails. Verifying and authenticating your domain ensures that your emails are delivered reliably and helps prevent your emails from being marked as spam. This process helps establish your domain's credibility and ensures compliance with email sending standards.
      </p>

      <p className="tutorial-intro">Follow these steps to verify and authenticate your domain:</p>

      <div className="tutorial-step">
        <h2 className="tutorial-step-title">Step 1: Add DNS Records</h2>
        <p className="tutorial-step-text">Add the provided DNS records to your domain provider's DNS settings.</p>
      </div>

      <div className="tutorial-step">
        <h2 className="tutorial-step-title">Step 2: Authenticate Domain</h2>
        <p className="tutorial-step-text">Once you have added the DNS records, click the "Authenticate" button. Your domain status will turn green and show "Authenticated" once the DNS records are correctly set up.</p>
      </div>

      <div className="tutorial-step">
        <h2 className="tutorial-step-title">Step 3: Start Sending Emails</h2>
        <p className="tutorial-step-text">After your domain is authenticated, you can start sending emails using our platform.</p>
      </div>
    </div>
  );
};

export default VerificationTutorial;
