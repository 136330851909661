import React from 'react';
import { useNavigate } from 'react-router-dom';

const SubscriptionAlertModal = ({ onClose }) => {
  const navigate = useNavigate();

  const handleSubscribeClick = () => {
    navigate('/subscriptions');
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>You don't have enough credits</h2>
        <p>Please subscribe to one of our plans to continue</p>
        <button className="subscribe-button" onClick={handleSubscribeClick}>Subscribe Now</button>
        <button className="close-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default SubscriptionAlertModal;
