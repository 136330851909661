import React from 'react';
import './Modal.css'

function Modal({ title, content, onClose, children }) {
  return (
    <div className="overlay">
      <div className="modal">
        <h2>{title}</h2>
        <p>{content}</p>
        {children}
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

export default Modal;