// components/FAQ.js
import React, { useState } from 'react';
import './FAQ.css';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const questionsAndAnswers = [
    { question: "What is Grafema?", answer: "Grafema helps you send 100% personalized cold emails using AI." },
    { question: "How does it work?", answer: "Grafema scrapes websites you provide, extracts email addresses and generates email messages for each contact, that you can send with a few clicks." },
    { question: "Do I need to know how to code?", answer: "No, you don't need to know how to code to use Grafema. Our tool is designed to be user-friendly." },
    { question: "Which websites can I use Grafema for?", answer: "Grafema can attempt to scrape any website you provide. It extracts email addresses and generates email messages with AI for each site. However, some websites may be more challenging to scrape or may not contain email addresses, which can result in some sites not yielding successful results." },
    { question: "Is my data secure on Grafema?", answer: "Yes, your data is securely stored and managed in compliance with all relevant privacy regulations." },
    { 
      question: "How do we use your data?", 
      answer: "We use your provided email to send the emails you generate through our tool. Additionally, we track openings of these emails to help you manage your campaigns more effectively." 
    },    
    { question: "Is there a free trial available?", answer: "Yes, we offer a free trial for new users to explore our tool." },
    { question: "Have more questions?", answer: "Feel free to contact our support team if you have more questions hello@grafema.xyz" }
  ];

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-section">
      <h2>FAQ</h2>
      {questionsAndAnswers.map((item, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => handleClick(index)}>
            {item.question}
            <span className="faq-icon">{activeIndex === index ? '−' : '+'}</span>
          </div>
          {activeIndex === index && <div className="faq-answer">{item.answer}</div>}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
