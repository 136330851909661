import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

mixpanel.init('7c4d281c7bf333522cc04c068236a862');

const PageTracker = () => {
  const location = useLocation();
  const previousPathRef = useRef();

  useEffect(() => {
    // Track the initial page view only if not already tracked
    if (previousPathRef.current !== location.pathname) {
      console.log('Page viewed:', location.pathname);
      mixpanel.track('Page Viewed', {
        page: location.pathname,
      });
      previousPathRef.current = location.pathname;
    }

    // Track subsequent page views
    const handleRouteChange = () => {
      if (location.pathname !== previousPathRef.current) {
        console.log('Page viewed:', location.pathname);
        mixpanel.track('Page Viewed', {
          page: location.pathname,
        });
        previousPathRef.current = location.pathname;
      }
    };

    // Listen for route changes
    handleRouteChange();

    return () => {
      // No need to clean up in this effect
    };
  }, [location]);

  return null;
};

export default React.memo(PageTracker);
