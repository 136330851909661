// AcceptTerms.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

function AcceptTerms() {
    const navigate = useNavigate();

    const handleAcceptTerms = () => {
        // Logic to record terms acceptance, potentially updating user data
        navigate('/');  // After accepting, navigate to the homepage
    };

    return (
        <div className="terms-acceptance">
            <h1>Accept Terms and Conditions</h1>
            <button onClick={handleAcceptTerms}>Accept Terms</button>
        </div>
    );
}

export default AcceptTerms;
