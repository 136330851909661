// src/components/Onboarding.js

import React, { useEffect } from 'react';
import './Onboarding.css'; // Create and import a CSS file for custom styles

const Onboarding = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="onboarding-container">
      <h1>Onboarding</h1>
      <p className="schedule-text">Please schedule your onboarding call:</p>
      <div className="calendly-inline-widget" data-url="https://calendly.com/grafema-ai/30min" style={{ minWidth: '320px', height: '700px' }}></div>
    </div>
  );
};

export default Onboarding;
