// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDow1yde4ydh7kx4wlC2A4GuVAeQxol6Ug",
  authDomain: "grafema-16ec1.firebaseapp.com",
  projectId: "grafema-16ec1",
  storageBucket: "grafema-16ec1.appspot.com",
  messagingSenderId: "180235770201",
  appId: "1:180235770201:web:f0247fdb18d64d27299448",
  measurementId: "G-EDVPJ3T1WB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app); // Initialize Firestore

// Export the auth module and GoogleAuthProvider for use in your components
export { auth, GoogleAuthProvider, db };