// AuthSuccess.js
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function AuthSuccess() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const isNewUser = params.get('isNewUser') === 'true';

        // Redirect to terms acceptance page if new, else to the homepage
        if (isNewUser) {
            navigate('/');  // Route where new users can accept terms
        } else {
            navigate('/');  // Existing users go straight to the homepage
        }
    }, [location, navigate]);

    return <div>Loading... Please wait.</div>;
}

export default AuthSuccess;
