import React from 'react';
import './PrivacyPolicy.css'; // Make sure to import the CSS file

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>Welcome to Grafema! We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at hello@grafema.xyz.</p>
      <p>This privacy policy applies to all information collected through our application, Grafema, and/or any related services, sales, marketing, or events (we refer to them collectively in this privacy policy as the "Services").</p>
      <h2>1. What Information Do We Collect?</h2>
      <p>In Short: We collect personal information that you provide to us such as name, address, contact information, passwords and security data, and information necessary for payment processing.</p>
      <p>We collect personal information that you voluntarily provide to us when you register at the Services, express an interest in obtaining information about us or our products and services, when participating in activities on the Services or otherwise contacting us.</p>
      <p>The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect can include the following:</p>
      <ul>
        <li><strong>Name and Contact Data:</strong> We collect your first and last name, email address, postal address, phone number, and other similar contact data.</li>
        <li><strong>Credentials:</strong> We collect passwords, password hints, and similar security information used for authentication and account access.</li>
        <li><strong>Payment Data:</strong> We process your payment data through Stripe, which handles your payment instrument details securely. We do not store these details on our servers.</li>
      </ul>
      <h2>2. How Do We Use Your Information?</h2>
      <p>In Short: We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</p>
      <p>We use personal information collected via our Services for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.</p>
      <ul>
        <li>To facilitate account creation and logon process. We use the information you provide to us to facilitate account creation and logon process for the performance of the contract.</li>
        <li>To send you marketing and promotional communications. We and/or our third-party marketing partners may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt-out of our marketing emails at any time (see the "Your Privacy Rights" section below).</li>
        <li>To send emails on your behalf. We use Brevo to send the emails you generate through our tool. This includes accessing your Brevo account with your explicit consent and sending personalized emails as part of your email campaigns.</li>
        <li>To track email replies. We monitor and track the replies to the emails sent from your Brevo account through our tool. This helps you manage and analyze the effectiveness of your email campaigns.</li>
      </ul>
      <h2>3. Will Your Information Be Shared With Anyone?</h2>
      <p>In Short: We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</p>
      <p>We may process or share data based on the following legal basis:</p>
      <ul>
        <li><strong>Consent:</strong> We may process your data if you have given us specific consent to use your personal information for a specific purpose.</li>
        <li><strong>Legitimate Interests:</strong> We may process your data when it is reasonably necessary to achieve our legitimate business interests.</li>
        <li><strong>Performance of a Contract:</strong> Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</li>
        <li><strong>Legal Obligations:</strong> We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</li>
      </ul>
      <h2>4. How Do We Keep Your Information Safe?</h2>
      <p>In Short: We aim to protect your personal information through a system of organizational and technical security measures.</p>
      <p>We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the services within a secure environment.</p>
      <h2>5. Do We Collect Information from Minors?</h2>
      <p>In Short: We do not knowingly collect data from or market to children under 18 years of age.</p>
      <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services.</p>
      <h2>6. Your Privacy Rights</h2>
      <p>In Short: You may review, change, or terminate your account at any time.</p>
      <p>If you have questions or comments about your privacy rights, you may email us at hello@grafema.xyz.</p>
      <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.</p>
      <h2>7. Controls for Do-Not-Track Features</h2>
      <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy.</p>
      <h2>8. Do We Make Updates to This Policy?</h2>
      <p>In Short: Yes, we will update this policy as necessary to stay compliant with relevant laws.</p>
      <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
      <h2>9. How Can You Contact Us About This Policy?</h2>
      <p>If you have questions or comments about this policy, you may email us at hello@grafema.xyz.</p>
      <p>Grafema</p>
    </div>
  );
};

export default PrivacyPolicy;
