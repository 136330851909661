import React, { useEffect, useState } from 'react';

function BusinessInfoDisplay({ businessInfo, onUpdateSummary, onToggleEmailSelection, remainingCredits }) {
  const [visibleEmails, setVisibleEmails] = useState({});
  const [editableSummaries, setEditableSummaries] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [generatedEmailsCount, setGeneratedEmailsCount] = useState(0);

  useEffect(() => {
    setSelectAll(businessInfo.every(bi => bi.isSelected));
    console.log('Business Info Updated:', businessInfo);

    // Update the count of generated emails
    const count = businessInfo.filter(info => info.emails.length > 0 && info.summary && info.summary.trim()).slice(0, remainingCredits).length;
    setGeneratedEmailsCount(count);
  }, [businessInfo, remainingCredits]);

  const toggleEmailVisibility = (id) => {
    setVisibleEmails(prevState => ({ ...prevState, [id]: !prevState[id] }));
  };

  const handleSummaryChange = (id, newSummary) => {
    setEditableSummaries(prev => ({ ...prev, [id]: newSummary }));
    onUpdateSummary(id, newSummary);
  };

  const handleEmailSelectionChange = (id) => {
    onToggleEmailSelection(id);
  };

  const toggleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    businessInfo.forEach((info) => onToggleEmailSelection(info.url, newSelectAll));
  };

  const filteredBusinessInfo = businessInfo.filter(info => info.emails.length > 0 && info.summary && info.summary.trim()).slice(0, remainingCredits);

  return (
    <div>
      {filteredBusinessInfo.length > 0 && (
        <div>
          <input
            type="checkbox"
            checked={selectAll}
            onChange={toggleSelectAll}
            className="select-all-checkbox"
          />
          <label> Select/Deselect All Emails</label>
         
        </div>
      )}
      {filteredBusinessInfo.map((info) => {
        return (
          <div key={info.url} className="business-info-container">
            <div className='business-info-details'>
              <p className="business-name">{info.url}</p>
              <p className="business-email">{info.emails.join(', ') || 'None'}</p>
              <input
                type="checkbox"
                checked={info.isSelected}
                onChange={() => handleEmailSelectionChange(info.url)}
                className="email-select-checkbox"
              />
              <button className="email-toggle-btn" onClick={() => toggleEmailVisibility(info.url)}>
                {visibleEmails[info.url] ? 'Hide Email' : 'Show Email'}
              </button>
            </div>
            {visibleEmails[info.url] && (
              <textarea
                className="email-content"
                value={editableSummaries[info.url] || info.summary}
                onChange={(e) => handleSummaryChange(info.url, e.target.value)}
                rows="4"
              ></textarea>
            )}
            
          </div>
          
        );
      })}
       <p>{generatedEmailsCount} generated emails</p>
    </div>
  );
}

export default BusinessInfoDisplay;