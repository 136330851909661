import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const Success = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('Confirming your subscription...');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');

    if (sessionId) {
      axios.get(`/api/confirm-payment?session_id=${sessionId}`)
        .then(response => {
          if (response.data.success) {
            setMessage('Payment successful! Redirecting to homepage...');
            setTimeout(() => navigate('/'), 3000);  // Redirect after 3 seconds
          } else {
            setMessage('Payment confirmation failed. Please contact support.');
          }
        })
        .catch(error => {
          console.error('Error confirming payment:', error);
          setMessage('An error occurred while confirming your payment.');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setMessage('No session ID found. Please try again.');
      setLoading(false);
    }
  }, [location.search, navigate]);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <p>{message}</p>
      )}
    </div>
  );
};

export default Success;
