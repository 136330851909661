import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import CampaignsDisplay from './components/dashboard/CampaignsDisplay';
import WebsiteInputForm from './components/WebsiteInputForm';
import UserGoalInputForm from './components/UserGoalInputForm';
import BusinessInfoDisplay from './components/BusinessInfoDisplay';
import AuthSuccess from './components/AuthSuccess';
import AcceptTerms from './components/AcceptTerms';
import Onboarding from './components/Onboarding'; // Import Onboarding component
import Waiting from './components/Waiting'; // Import Waiting component
import PrivacyPolicy from './components/PrivacyPolicy';
import SignPage from './components/SignPage';
import Subscription from './components/Subscription';
import Success from './components/Success';
import FAQ from './components/FAQ';
import Modal from './components/Modal';
import SubscriptionAlertModal from './components/SubscriptionAlertModal';
import DomainVerification from './components/DomainVerification';
import VerificationTutorial from './components/VerificationTutorial'; 
import { auth } from './firebase-config';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import { useLocation } from 'react-router-dom';
import PageTracker from './components/PageTracker';
import './App.css';

axios.defaults.withCredentials = true;

function AppContent() {



  const [businessInfo, setBusinessInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showGenerateButton, setShowGenerateButton] = useState(true);
  const [error, setError] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSubscriptionAlert, setShowSubscriptionAlert] = useState(false);
  const [userCredentials, setUserCredentials] = useState(() => {
    return JSON.parse(localStorage.getItem('userCredentials')) || null;
  });
  
  const [userGoal, setUserGoal] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [emailSubject, setEmailSubject] = useState('');
  const [signature, setSignature] = useState('');
  const [jobsDone, setJobsDone] = useState(0);
  const [totalJobs, setTotalJobs] = useState(0);
  const [emailsProcessed, setEmailsProcessed] = useState(false);
  const [remainingCredits, setRemainingCredits] = useState(null);
  const [resultCount, setResultCount] = useState(0);  // Added state for result count
  const [noResults, setNoResults] = useState(false); // Added state for no results
  const [onboarded, setOnboarded] = useState(null);
  const [isDomainAuthenticated, setIsDomainAuthenticated] = useState(false); // Assume false initially
  const [showDomainVerificationModal, setShowDomainVerificationModal] = useState(false);
  const [showEmailVerificationModal, setShowEmailVerificationModal] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [language, setLanguage] = useState('English'); // Add this line to manage language state
  const [initialLoading, setInitialLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const isAuthPage = location.pathname === '/sign';


  useEffect(() => {
    const auth = getAuth();
    const unregisterAuthObserver = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (!user.emailVerified) {
          setShowEmailVerificationModal(true);
          setIsLoggedIn(false);
          return;
        }
        user.getIdToken(true).then((idToken) => {
          axios.post('/api/session-login', { token: idToken }).then(response => {
            const { user, onboarded } = response.data;
            const newCredentials = {
              uid: user.uid,
              email: user.email,
              displayName: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              idToken,
              accessToken: userCredentials?.accessToken,
              refreshToken: userCredentials?.refreshToken,
            };
            setUserCredentials(newCredentials);
            setIsLoggedIn(true);
            setOnboarded(onboarded); // Set onboarding status
            console.log(onboarded);
            localStorage.setItem('userCredentials', JSON.stringify(newCredentials));
            fetchUserCredits(newCredentials.email);
            // Start domain status check after user is logged in
            checkDomainStatus();
          }).catch(error => {
            console.error('Failed to fetch user data:', error);
          });
        });
      } else {
        setIsLoggedIn(false);
        setUserCredentials(null);
        setOnboarded(null);
        localStorage.removeItem('userCredentials');
      }
    });
  
    return () => unregisterAuthObserver();
  }, []);
  
  
  
  
  useEffect(() => {
    if (isLoggedIn) {
      checkDomainStatus();
    }
  }, [isLoggedIn]);
  
  

  useEffect(() => {
    axios.get('/api/check-login-status', { withCredentials: true })
      .then(response => {
        if (response.data.isLoggedIn && !isLoggedIn) {
          setIsLoggedIn(true);
          setUserCredentials(response.data.user);
          fetchUserCredits(response.data.user.email);
        } else if (!response.data.isLoggedIn && isLoggedIn) {
          setIsLoggedIn(false);
          setUserCredentials(null);
          setRemainingCredits(null);
        }
      })
      .catch(error => {
        console.error('Failed to check session:', error);
      });
  }, [isLoggedIn]);

  const fetchUserCredits = async (email) => {
    try {
      const { data } = await axios.get(`/api/get-subscription?email=${encodeURIComponent(email)}`);
      
      if (data) {
        const { planId, emailCount, remainingCredits: apiRemainingCredits } = data;
        
        // If remaining credits are provided by the API, use them
        if (apiRemainingCredits !== undefined) {
          setRemainingCredits(apiRemainingCredits);
          return;
        }
  
        const planLimits = {
          "price_1PcrgVJ6rUibtzJ0quX6x71T": 1000,
          "price_1PIqffJ6rUibtzJ0svrflprp": 2500
        };
  
        // Default to 10 for users without a subscription
        const limit = planLimits[planId] || 10;
        const remaining = limit - emailCount;
        setRemainingCredits(remaining);
      }
    } catch (error) {
      console.error('Error fetching user credits:', error);
      setRemainingCredits(null);
    }
  };

  const handleEmailSubjectChange = (newSubject) => {
    setEmailSubject(newSubject);
  };

  const handleSignatureChange = (newSignature) => {
    setSignature(newSignature);
  };

  const updateBusinessInfoSummary = (index, newSummary) => {
    setBusinessInfo(currentInfo =>
      currentInfo.map((info, idx) => idx === index ? { ...info, summary: newSummary } : info)
    );
  };

  const toggleEmailSelection = (url, isSelected) => {
    setBusinessInfo(currentInfo =>
      currentInfo.map((info) => info.url === url ? { ...info, isSelected: isSelected } : info)
    );
  };
  

  const handleSendEmails = async () => {
    console.log(userCredentials);
    if (!isLoggedIn || !userCredentials || userCredentials.emailCredits <= 0) {
      setShowSubscriptionAlert(true);
      return;
    }
  
    const selectedBusinesses = businessInfo.filter(business => business.isSelected);
    if (selectedBusinesses.length === 0) {
      alert('No emails have been selected to send.');
      return;
    }
  
    const campaignId = `Campaign_${Date.now()}`;
    const senderName = `${userCredentials.firstName} ${userCredentials.lastName}`;
    console.log(emailSubject);
  
    setIsSending(true); // Set loading state to true
  
    try {
      for (const business of selectedBusinesses) {
        const emailDetails = {
          to: business.emails[0],
          subject: emailSubject,
          text: business.summary,
          userId: userCredentials.uid,
          senderName: senderName,
          campaignId
        };
  
        const response = await axios.post('/api/send-email', emailDetails, {
          withCredentials: true
        });
        console.log('Email sent successfully:', response.data.messageId);
      }
  
      // Track the "Send Emails" button click event
      mixpanel.track('Send Emails Clicked', {
        emailSubject: emailSubject,
        signature: signature,
        numberOfEmails: selectedBusinesses.length,
      });
  
      const newCredits = userCredentials.emailCredits - selectedBusinesses.length;
      setUserCredentials({ ...userCredentials, emailCredits: newCredits });
      localStorage.setItem('userCredentials', JSON.stringify({ ...userCredentials, emailCredits: newCredits }));
      setEmailSent(true);
    } catch (error) {
      console.error("Error sending email:", error);
      alert('Failed to send email: ' + error.message);
      return;
    } finally {
      setIsSending(false); // Set loading state to false
    }
  };
  
  
  const checkDomainStatus = async () => {
    try {
      const response = await axios.get('/api/check-domains', { params: { userId: userCredentials.uid } });
      const domains = response.data.domains || [];
      const authenticated = domains.some(domain => domain.authenticated);
      setIsDomainAuthenticated(authenticated);
    } catch (error) {
      console.error('Failed to check domain status:', error);
    }
  };
  
  
  

  const onclose = () => {
    setShowModal(false);
  }

  const handleGoalSubmit = async (goal) => {
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (user && !user.emailVerified) {
      setShowEmailVerificationModal(true);
      return;
    }
  
    if (!isLoggedIn) {
      navigate('/sign'); // Navigate to the sign page
    } else {
      await checkDomainStatus();
      if (!isDomainAuthenticated) {
        setShowDomainVerificationModal(true);
      } else {
        setUserGoal(goal);
        setCurrentStep(currentStep + 1);
      }
    }
  };
  
  
  const handleLanguageChange = (e) => {
    setLanguage(e.target.value); // Update language state when user selects a language
    console.log('Selected language:', e.target.value); // Debug log to verify language selection
  };
    
  
  
  

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const fetchBusinessInfo = (data, isFile, selectedLanguage) => {
    setLoading(true);
    setError(null);
    setNoResults(false);  // Reset no results state
    setLanguage(selectedLanguage); // Update the language state
  
    const config = {
      headers: isFile ? {} : { 'Content-Type': 'application/json' },
      withCredentials: true
    };
  
    const urls = Array.isArray(data) ? data : [data];
    setTotalJobs(urls.length);
  
  const payload = {
    urls: urls,
    userGoal: userGoal,
    signature: signature,
    language: selectedLanguage, // Use the selected language directly
    userEmail: userCredentials.email
  };

  console.log('Payload language:', selectedLanguage); 
  
    const apiUrl = '/api/business-info';
  
    axios.post(apiUrl, payload, config)
      .then(response => {
        setLoading(false);
        console.log('Response data:', response.data);
  
        if (response.status === 202 && response.data.campaignId) {
          const newBusinessInfo = urls.map(url => ({
            url: url,
            summary: 'Processing...',
            isSelected: false,
            emails: [],
            campaignId: response.data.campaignId
          }));
          setBusinessInfo(newBusinessInfo);
          pollForResults(response.data.campaignId);
        } else {
          throw new Error(`Unexpected response status: ${response.status}`);
        }
      })
      .catch(error => {
        setLoading(false);
        if (error.response && error.response.status === 403) {
          setShowSubscriptionAlert(true);
        } else {
          console.error('There was an error fetching business info:', error);
          setError({ message: error.response?.data?.error || 'An unexpected error occurred' });
        }
      });
  };
  
  
  const pollForResults = (campaignId) => {
    const interval = setInterval(() => {
      console.log('Polling for results...');
      axios.get(`/api/results?campaignId=${campaignId}`, { withCredentials: true })
        .then(response => {
          const { allDone, results, jobsDone, status } = response.data;
          console.log('Poll response:', response.data);
  
          setJobsDone(jobsDone);
  
          if (status === 'ready' || status === 'no_results') {
            clearInterval(interval);
            setLoading(false);
            console.log('Results ready or no results found:', results);
  
            if (status === 'no_results' || results.length === 0) {
              console.log('No results found.');
              setNoResults(true);
              setBusinessInfo([]);
            } else {
              console.log('Results found:', results);
              setNoResults(false);
              setBusinessInfo(prevInfo => {
                const newResults = results.map(result => ({
                  ...result,
                  isSelected: true,
                  summary: result.emailDraft, // Use emailDraft as summary
                }));
                setShowGenerateButton(false);
                let updatedInfo = [...prevInfo];
                newResults.forEach(newItem => {
                  const index = updatedInfo.findIndex(info => info.url === newItem.url);
                  if (index > -1) {
                    updatedInfo[index] = { ...updatedInfo[index], ...newItem };
                  } else {
                    updatedInfo.push(newItem);
                  }
                });
                console.log('Updated business info:', updatedInfo);
                setEmailsProcessed(true);
                return updatedInfo;
              });
            }
            setShowErrorModal(false);
            setEmailSent(false);
          } else {
            console.log('Results not ready, checking again...');
          }
        })
        .catch(error => {
          console.error('Error polling for results:', error);
          setError({ message: 'Error fetching results' });
          clearInterval(interval);
          setLoading(false);
        });
    }, 5000);
  };
  
  
  
  const closeErrorModal = () => {
    setShowModal(false);
  }

  const handleSignOut = () => {
    console.log("Initiating sign out...");
  
    axios.get('/api/logout', { withCredentials: true })
      .then(response => {
        console.log("Backend logout successful.");
  
        localStorage.removeItem('userCredentials');
        setUserCredentials(null);
        setIsLoggedIn(false);
  
        const auth = getAuth();
        auth.signOut().then(() => {
          console.log("Firebase sign out successful.");
          window.location.href = '/login';
        }).catch((error) => {
          console.error("Firebase sign out failed:", error);
        });
      })
      .catch(error => {
        console.error("Backend logout failed:", error);
      });
  };
  

  const handleSignIn = async (token) => {
    try {
      const response = await axios.post('/api/session-login', { token });
      const { data } = response;
      
      const newCredentials = {
        uid: data.uid,
        email: data.email,
        displayName: data.displayName || data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        idToken: token,
        accessToken: userCredentials?.accessToken,
        refreshToken: userCredentials?.refreshToken,
      };
      
      setUserCredentials(newCredentials);
      setIsLoggedIn(true);
      localStorage.setItem('userCredentials', JSON.stringify(newCredentials));
      fetchUserCredits(newCredentials.email);
      setShowModal(false);
    } catch (error) {
      console.error('Error handling sign-in:', error);
      alert('Failed to handle sign-in: ' + error.message);
    }
  };
  
  
  
  useEffect(() => {
    const checkSignupAvailability = async () => {
      try {
        // Your existing code to check login status, etc.
        // ...
      } catch (error) {
        console.error('Error during initial load:', error);
      } finally {
        setInitialLoading(false);
      }
    };

    checkSignupAvailability();
  }, []);

  if (initialLoading) {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
      <div className='App'>
          {!isAuthPage && (
        <div className='header'>
          <div>
            <Link to="/" className="link">Home</Link>
            {isLoggedIn && <Link to="/campaigns" className="link">Campaigns</Link>}
            {isLoggedIn && <Link to="/subscriptions" className="link">Manage Subscription</Link>}
            {isLoggedIn && <Link to="/verify-domain">Verify Domain</Link>}
          </div>
          {isLoggedIn ? (
            <div>
              {remainingCredits !== null && (
                <span className="credits-info">{remainingCredits} credits left</span>
              )}
              <span className="welcome-message">Welcome, {userCredentials?.email}</span>
              
              <button onClick={handleSignOut} className="sign-out-button">Sign out</button>
            </div>
          ) : (
            <Link to="/sign" className='button-link'>Sign In</Link>
          )}
        </div>)}

        <Routes>
          <Route path="/auth-success" element={<AuthSuccess />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/accept-terms" element={<AcceptTerms />} />
          <Route path="/campaigns" element={<CampaignsDisplay />} />
          <Route path="/onboarding" element={<Onboarding />} /> {/* Add Onboarding route */}
          <Route path="/waiting" element={<Waiting />} /> {/* Add Waiting route */}
          <Route path="/verify-domain" element={<DomainVerification userCredentials={userCredentials}/>} />
          <Route path="/verification-tutorial" element={<VerificationTutorial />} />
          <Route path="/success" element={<Success />} /> {/* Add Success route */}
          <Route path="/sign" element={<SignPage onSignIn={handleSignIn} />} />
          <Route path="/subscriptions" element={<Subscription userCredentials={userCredentials} setUserCredentials={setUserCredentials} />} />
          <Route path="/"
            element={
              <div className='App'>
                {showErrorModal && (
                  <Modal
                    title="Subscription Required"
                    content="Please subscribe to one of our plans to keep sending emails."
                    onClose={() => closeErrorModal()}
                  />
                )}
                {showSubscriptionAlert && (
                  <SubscriptionAlertModal
                    onClose={() => setShowSubscriptionAlert(false)}
                  />
                )}
                {currentStep === 1 && (
                  <div>
                    <h1>Grafema.</h1>
                    <h3>Send 100% personalized cold emails with AI</h3>
                    <UserGoalInputForm onSubmitGoal={handleGoalSubmit} />
                    <FAQ />
                  </div>
                )}

  {currentStep > 1 && (
  <div>
    <button onClick={handleBack} className="back-button">
      ← Back
    </button>
    <div className="tool">
      <WebsiteInputForm
          onSubmitUrls={(urls, selectedLanguage) => {
            console.log('Language passed to AppContent:', selectedLanguage); // Debug log
            fetchBusinessInfo(urls, false, selectedLanguage); // Pass selectedLanguage to fetchBusinessInfo
          }}
        onEmailSubjectChange={handleEmailSubjectChange}
        onSignatureChange={handleSignatureChange}
        loading={loading}
        businessInfo={businessInfo}
        userCredentials={userCredentials}
        userGoal={userGoal}
        showGenerateButton={showGenerateButton}
      />
      {error && <p>Error: {error.message}</p>}
      {noResults && <p>No results for the provided URLs</p>}
      {resultCount > 0 && <p>Number of results: {resultCount}</p>}
      <BusinessInfoDisplay
        businessInfo={businessInfo}
        onUpdateSummary={updateBusinessInfoSummary}
        onToggleEmailSelection={toggleEmailSelection}
        showErrorModal={showErrorModal}
        closeErrorModal={() => setShowErrorModal(false)}
        remainingCredits={remainingCredits}
      />
     
{
  totalJobs > 0 && (
    <div className="progress-bar-container">
      <div className="progress-bar">
        <div
          className="progress"
          style={{ width: `${(jobsDone / totalJobs) * 100}%` }}
        ></div>
      </div>
      <p>{Math.min(jobsDone, totalJobs)} out of {totalJobs} websites processed</p>
    </div>
  )
}

{businessInfo.length > 0 && (
  <div className='send-email-section'>
    {emailsProcessed ? (
      emailSent ? <p className='sent-message'>The emails have been sent</p> : 
      <button type="submit" onClick={handleSendEmails} disabled={loading || isSending}>
        {isSending ? <div className="button-spinner"></div> : 'Send Emails'}
      </button>
    ) : (
      <div className="spinner"></div>
    )}
  </div>
)}


    </div>
  </div>
)}

<footer className='footer'>
                <div className='footer-left'>
                  <p>© 2024 Grafema. All rights reserved.</p>
                </div>
                <div className='footer-right'>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSdEypkR29-lmab6wqGXDnF4a_0ZRWhN_-ecsmvvWW4-alclRA/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">Report Bug</a>
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSdDbncT_z2rkmM_nTWJ3xKQiuC_bINjdCcfuctDKy-N-QajwA/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">Give Feedback</a>
                </div>
              </footer>
              </div>
            }
          />
        </Routes>

       
        {showDomainVerificationModal && (
          <Modal
            title="Domain Verification Required"
            content="Please verify your domain to proceed."
            onClose={() => setShowDomainVerificationModal(false)}
          >
            <button onClick={() => navigate('/verify-domain')}>Verify Domain</button>
          </Modal>
        )}
        {showEmailVerificationModal && (
          <Modal
            title="Email Verification Required"
            content={verificationMessage || "Please verify your email before proceeding."}
            onClose={() => {
              setShowEmailVerificationModal(false);
              setVerificationMessage(''); // Reset message on close
            }}
          >
            {!verificationMessage && (
              <button onClick={() => {
                const user = auth.currentUser;
                if (user) {
                  sendEmailVerification(user).then(() => {
                    setVerificationMessage('Verification email sent.');
                    setTimeout(() => {
                      setShowEmailVerificationModal(false);
                      setVerificationMessage('');
                    }, 3000); // Auto-close the modal after 3 seconds
                  }).catch((error) => {
                    console.error('Error sending email verification:', error);
                  });
                }
              }}>Resend Verification Email</button>
            )}
          </Modal>
        )}




      </div>
  );
}

export default AppContent;