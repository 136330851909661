import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { loadStripe } from '@stripe/stripe-js';
import './Subscription.css';
import mixpanel from 'mixpanel-browser';

mixpanel.init('7c4d281c7bf333522cc04c068236a862');

const stripePromise = loadStripe('pk_live_51P93fKJ6rUibtzJ0CZnPPCaWPjSD6EaGNeojAw8FEB39byVzXiSdsHcs7uE67MT8E5jnVRkcKpzG1FfYnzI2GYq000pMhkvl21');

Modal.setAppElement('#root'); // Suppresses modal accessibility warnings

function Subscription({ userCredentials }) {
  const [subscription, setSubscription] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      if (userCredentials) {
        try {
          const { data } = await axios.get(`/api/get-subscription?email=${encodeURIComponent(userCredentials.email)}`);
          setSubscription(data);
        } catch (error) {
          console.error('Error fetching subscription details:', error);
          setSubscription(null);
        }
      }
    };

    fetchSubscriptionDetails();
  }, [userCredentials]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const handleSubscription = async (planId) => {
    if (!userCredentials) {
      alert('You must be logged in to subscribe.');
      return;
    }

    try {
      const response = await axios.post('/api/create-checkout-session', {
        email: userCredentials.email,
        planId
      });
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: response.data.sessionId
      });
      if (error) {
        console.log(error);
      } else {
        // Track successful plan selection event
        mixpanel.track('Plan Selected', {
          email: userCredentials.email,
          planId: planId,
          planName: planId === 'price_1PcrgVJ6rUibtzJ0quX6x71T' ? 'Grafema Pro' : 'Grafema Ultimate'
        });
      }
    } catch (error) {
      alert('Unable to process payment: ' + error.message);

      // Track plan selection error event
      mixpanel.track('Plan Selection Failed', {
        email: userCredentials.email,
        planId: planId,
        error: error.message
      });
    }
  };

  const handleCancelSubscription = async () => {
    setModalIsOpen(true);
  };

  const confirmCancelSubscription = async () => {
    try {
      const subscriptionResponse = await axios.get(`/api/get-subscription?email=${encodeURIComponent(userCredentials.email)}`);
      const { subscriptionId } = subscriptionResponse.data;

      const response = await axios.post('/api/cancel-subscription', {
          email: userCredentials.email,
          subscriptionId
      });

      if (response.status === 200) {
          alert('Subscription cancelled successfully.');
          setModalIsOpen(false);
          setSubscription(null); // or update subscription status accordingly

          // Track subscription cancellation event
          mixpanel.track('Subscription Cancelled', {
            email: userCredentials.email,
            subscriptionId: subscriptionId
          });
      } else {
          throw new Error(response.data.error || 'Unknown error');
      }
    } catch (error) {
      alert('Failed to cancel subscription: ' + error.message);
      setModalIsOpen(false);

      // Track subscription cancellation error event
      mixpanel.track('Subscription Cancellation Failed', {
        email: userCredentials.email,
        error: error.message
      });
    }
  };

  const renderSubscriptionDetails = () => {
    if (!subscription) {
      return <p>No active subscription.</p>;
    }

    const { planName, status, currentPeriodEnd, planId } = subscription;

    if (!planId) {
      return <p>No active subscription.</p>;
    }

    return (
      <>
        <p>Plan: {planName} ({status})</p>
        {status === 'cancelled' || status === 'deleted' || status === 'expired' ? (
          <>
            <p>Ends on: {formatDate(currentPeriodEnd)}</p>
            <button className='renew-button' onClick={() => handleSubscription(planId)}>Renew Subscription</button>
          </>
        ) : (
          <>
            <p>Renews on: {formatDate(currentPeriodEnd)}</p>
            <button className='cancel-button' onClick={handleCancelSubscription}>Cancel Subscription</button>
          </>
        )}
      </>
    );
  };

  return (
    <div className="subscription-container">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Cancel Subscription Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <h2>Confirm Cancellation</h2>
        <p>Are you sure you want to cancel your subscription?</p>
        <button onClick={confirmCancelSubscription}>Yes, Cancel Subscription</button>
        <button onClick={() => setModalIsOpen(false)}>No, Keep Subscription</button>
      </Modal>
      <h2>Your Current Plan</h2>
      {renderSubscriptionDetails()}
      <h2>Upgrade Your Plan</h2>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="plan-card pro-plan">
          <h3>Grafema Pro</h3>
          <ul>
            <li>1000 AI generated emails / month</li>
            <li>Analytics (beta)</li>
          </ul>
          <p className="price">€49 / month</p>
          <button onClick={() => handleSubscription('price_1PcrgVJ6rUibtzJ0quX6x71T')}>Choose Pro</button>
        </div>
        <div className="plan-card ultimate-plan">
          <h3>Grafema Ultimate</h3>
          <ul>
            <li>2500 AI generated emails / month</li>
            <li>Analytics (beta)</li>
          </ul>
          <p className="price">€99 / month</p>
          <button onClick={() => handleSubscription('price_1PIqffJ6rUibtzJ0svrflprp')}>Choose Ultimate</button>
        </div>
      </div>
    </div>
  );
}

export default Subscription;
