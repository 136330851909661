import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './DomainVerification.css';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';

mixpanel.init('7c4d281c7bf333522cc04c068236a862');

const DomainVerification = ({ userCredentials }) => {
  const [dnsRecords, setDnsRecords] = useState([]);
  const [error, setError] = useState(null);
  const [domainsStatus, setDomainsStatus] = useState([]);
  const [domainExistsOnBrevo, setDomainExistsOnBrevo] = useState(false);
  const [loading, setLoading] = useState(true);

  const userId = userCredentials.uid;
  const userDomain = userCredentials.email.split('@')[1];

  useEffect(() => {
    checkDomainOnBrevo();
  }, []);

  const checkDomainOnBrevo = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/check-domain-on-brevo', { params: { domain: userDomain } });
      setDomainExistsOnBrevo(response.data.exists);
      if (response.data.exists) {
        fetchDomainsStatus();
      }
    } catch (error) {
      console.error('Failed to check domain on Brevo:', error);
      setError('Failed to check domain status. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('/api/add-domain', { email: userCredentials.email, userId });
      const records = response.data.dns_records || [];
      setDnsRecords(records.filter(record => record && record.type));
      setDomainExistsOnBrevo(true);
      setError(null);
      fetchDomainsStatus();

      mixpanel.track('Domain Verification Submitted', {
        domain: userDomain,
        userId: userId
      });
    } catch (error) {
      setDnsRecords([]);
      setError('Failed to add domain. Please try again.');

      mixpanel.track('Domain Verification Failed', {
        domain: userDomain,
        userId: userId,
        error: error.message
      });
    }
  };

  const handleAuthenticate = async (domain) => {
    try {
      await axios.put('/api/authenticate-domain', { domain, userId });
      fetchDomainsStatus();

      mixpanel.track('Domain Authentication Submitted', {
        domain: domain,
        userId: userId
      });
    } catch (error) {
      console.error('Failed to authenticate domain:', error);

      mixpanel.track('Domain Authentication Failed', {
        domain: domain,
        userId: userId,
        error: error.message
      });
    }
  };

  const fetchDomainsStatus = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/check-domains', { params: { userId } });
      const domains = Array.isArray(response.data.domains) ? response.data.domains : [];
      console.log("Fetched Domains Status:", JSON.stringify(domains, null, 2));
      setDomainsStatus(domains);

      const userDomainStatus = domains.find(domain => domain.domain === userDomain);
      if (userDomainStatus && userDomainStatus.dns_records) {
        const formattedRecords = Object.values(userDomainStatus.dns_records).map(record => ({
          type: record.type,
          host_name: record.host_name,
          value: record.value
        }));
        setDnsRecords(formattedRecords);
      }
    } catch (error) {
      console.error('Failed to fetch domains status:', error);
      setError('Failed to fetch domain status. Please try again.');
    } finally {
      setLoading(false);
    }
  };



  if (loading) {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="domain-verification-container">
      {!domainExistsOnBrevo ? (
        <>
          <h2 className="domain-verification-title">Verify Your Domain</h2>
          <p className='domain-verification-p'>
            Before you can start sending emails, you need to verify the domain of your email address. 
            <Link to="/verification-tutorial" className="why-link">Why?</Link>
          </p>
          <form className="domain-verification-form" onSubmit={handleSubmit}>
            <button className="domain-verification-button" type="submit">Verify</button>
          </form>
        </>
      ) : (
        <>
          {error && <p className="domain-verification-error">{error}</p>}
          <h3 className="domain-verification-subtitle">Your Domains</h3>
<ul className="domain-verification-list">
  {domainsStatus.map(({ domain, authenticated }) => (
    <li key={domain} className="domain-verification-list-item">
      <span className="domain-verification-domain-name">{domain}</span>
      <span className={`domain-verification-domain-status ${authenticated ? 'domain-verification-authenticated' : 'domain-verification-not-authenticated'}`}>
        {authenticated ? 'Authenticated' : 'Not Authenticated'}
      </span>
      {!authenticated && (
        <button className="domain-verification-button" onClick={() => handleAuthenticate(domain)}>Authenticate</button>
      )}
    </li>
  ))}
</ul>
{dnsRecords.length > 0 && (
  
  <div className="domain-verification-records">
    <h3 className="domain-verification-subtitle">DNS Records</h3>
    <table className="domain-verification-table">
      <thead>
        <tr>
          <th className="domain-verification-th">Type</th>
          <th className="domain-verification-th">Name</th>
          <th className="domain-verification-th">Value</th>
        </tr>
      </thead>
      <tbody>
        {dnsRecords.map((record, index) => (
          <tr key={index}>
            <td className="domain-verification-td">{record.type}</td>
            <td className="domain-verification-td">{record.host_name}</td>
            <td className="domain-verification-td domain-verification-td-wrap">{record.value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)}


</>
)}

      <div className="tutorial-link-container">
        <Link to="/verification-tutorial" className="tutorial-link">
          Not sure how to do it? Check our tutorial
        </Link>
      </div>
    </div>
  );
};

export default DomainVerification;